import React from "react";


const AboutUs = () =>{
return<>
<div style={{'backgroundColor': ' #fff'}}>
<div className="container-xl" >
 <br />
 <h3>How Can You Contact Us? </h3>

<p>If you have any questions or want to seek clarifications about this Policy, or any complaints, comments, concerns, or feedback about our Data privacy and protection practices, you may contact at:</p>
<p>Gravity Technologies</p>
<p>E-mail: support@gravitytechnologies.online</p>
<p>Phone: 6232559154</p>
<p>Address: 305 Satguru Parinay, Indore 452001, Madhya Pradesh, India</p>



<p>Office Hours: 10 am to 5 pm</p>
    
    </div>
    </div>

</>

}; 


export default AboutUs;

