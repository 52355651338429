import './App.css';


import { BrowserRouter, Routes, Route } from "react-router-dom";
import ButtonAppBar from "./home/appBar";
import HomePage from './home/home';
import Footer from "./home/footer";
import AboutUs from "./home/aboutus";
import ContactUs from "./home/contact";
import SnackGame from "./games/snake/SnakeGame";

// Footer 

import PrivacyPolicy from "./home/privacyPolicy";
import ReferEarn from "./home/refer&Eearn";
import AntiMonyLaunderingPolicy from "./home/antiMoneyLaunderingPolicy";
import ResponsibleGamingPolicy from "./home/responsibleGamingPolicy";
import TermsCondition from './home/t&c';
import PreOrder from './home/recharge';
import RechargeHistory from './home/history';

 
function App() {
  return (
    <div className="App">
    
     {/* <ButtonAppBar /> */}
     <Routes>
      <Route path='/' element={<HomePage />}/>
      <Route path='/about' element={<AboutUs />}/>
      <Route path='/contact' element={<ContactUs />}/>
      <Route path='/snakegame' element={<SnackGame />}/>


     {/* footer routes */}
       <Route path='/t&c' element={<TermsCondition/>}/>
       <Route path='/privacyPolicy' element={<PrivacyPolicy />}/>
       <Route path='/referEarn' element={<ReferEarn />}/>
       <Route path='/anti-money-laundering-policy' element={<AntiMonyLaunderingPolicy />}/>
       <Route path='/responsible-gaming-policy' element={<ResponsibleGamingPolicy />}/>
       




     </Routes>
     
     <Routes>
     <Route path="/recharge/:id/:token"
       element={<PreOrder />}
        />
        <Route path="/rechargeHistory/:id/:token"element={<RechargeHistory />}
        />
     </Routes>
     
     
    
    </div>
  );
}

export default App;
