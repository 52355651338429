import { Container, Typography, Button, Grid } from "@mui/material";
import React,{ useEffect, useState } from "react";
import chikkas from "../chikkas.png";
import Step1 from "../truewin step1.png";
import Step2 from "../truewin step2.png";
import Step3 from "../truewin step3.png";
import Step4 from "../truewin step4.png";
import Footer from "./footer";
import TopAppBar from "./appBar";


export default function HomePage() {

  const [link, setLink] = useState([]);

  // const fetchData = () => {
  //   return fetch("https://server.gravitytechnologies.online/checkUpdates")
  //         .then((response) => response.json())
  //         .then((data) => setLink(data.link));
  // }

  // useEffect(() => {
  //   fetchData();
  // },[])



  function myFunction() {
    
    window.open(link);
  }
  return (
    <>

      <TopAppBar></TopAppBar>
      <Container
        style={{ marginTop: "100px", marginBottom: "50px" }}
        maxWidth="sm"
      >
        <Typography variant="h3">Time to win</Typography>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Experience the best skill based games. Compete with live players and win. Redeem your winnings direct to your preferred payment accounts. Zero platform fees*
        </Typography>
        
        <Button onClick={myFunction}
          variant="contained"
          style={{ marginTop: "20px" }}
          //startIcon={<DownloadIcon />}
    
        >
          Download Now
        </Button>
        
      </Container>
      <Container style={{height: '50px'}}/>
      <Typography variant="h4" onClick = {window.scrollBy(0, 100)}>Games to play</Typography>
      <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{'padding': '20px'}}
      >
          <Grid item xs={12} sm={6}>
        <img src={chikkas} 
              alt="logo" style={{"maxWidth": "250px"}}/>
          
        </Grid>
        <Grid item xs={12} sm={6}>
        <Typography variant="h5">Tic Tac Toe</Typography>
          <Typography style={{"marginTop": "15px"}}>
          Tic-tac-toe game, noughts and crosses, or Xs and Os is a paper-and-pencil game for two players who take turns marking the spaces in a three-by-three grid with X or O. The player who succeeds in placing three of their marks in a horizontal, vertical, or diagonal row is the winner.
          </Typography>
        </Grid>
        
      </Grid>
      </Container>
      <Container style={{height: '50px'}}/>

      <Typography variant="h4" onClick = {window.scrollBy(0, 100)}>How To Install ? </Typography>
      <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{'padding': '20px'}}
      >
          {/* <Grid item xs={12} sm={6}>
        <img src={Step1} 
              alt="logo" style={{"maxWidth": "250px"}}/>
          
        </Grid> */}
        <Grid item xs={12} sm={6}>
        <Typography variant="h5">Step 1</Typography>
          <Typography style={{"marginTop": "15px"}}>
            Download Gravity Technologies apk file. If you promped a window telling This type of file.... Click OK, the file will be downloaded.
          </Typography>
        </Grid>
        
      </Grid>
      </Container>

      <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        style={{'padding': '20px'}}
      >
          {/* <Grid xs={12} sm={6}>
        <img src={Step2} 
              alt="logo" style={{"maxWidth": "250px"}} />
          
        </Grid> */}
         
        <Grid xs={12} sm={6}>
            <Typography variant="h5">Step 2</Typography>
          <Typography style={{"marginTop": "15px"}}>
            Then Click on Open button, Then click on setting button next to cancel button. Now chrome or any browser setting will be open.
          </Typography>
        </Grid>
        
        
      </Grid>
      </Container>
      <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        style={{'padding': '20px'}}
      >
          {/* <Grid xs={12} sm={6}>
        <img src={Step3} 
              alt="logo" style={{"maxWidth": "250px"}} />
          
        </Grid> */}
          
        <Grid xs={12} sm={6}>
        <Typography variant="h5">Step 3</Typography>
          <Typography style={{"marginTop": "15px"}}>
            Click on "Allow from this source", this will enable our app to be installed on your phone.
          </Typography>
        </Grid>
        
        
      </Grid>
      </Container>

     <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        style={{'padding': '10px'}}
      >
          {/* <Grid item xs={12} sm={6}>
        <img src={Step4} 
              alt="logo" style={{"maxWidth": "250px"}} />
          
        </Grid> */}
         
        <Grid item xs={12} sm={6}>
        <Typography variant="h5">Step 4</Typography>
          <Typography style={{"marginTop": "15px"}}>
           Click on install button and app will be successfully installed on your mobile phone.
          </Typography>
        </Grid>
        
        
      </Grid>
      </Container>
      <Container style={{height: '50px'}}/>
     <Footer></Footer>
      
      
    </>
  );
}
