import React from "react";


const ResponsibleGamingPolicy = () =>{
return<>
<div style={{'backgroundColor': ' #fff', }}>
<div className="container-xl" >
    <br /><h2>Responsible Gaming Policy</h2>
    <p>Gravity Technologies(the “Company”), the operator of this online gaming website, www.gravitytechnologies.online(“Website”, inclusive of the mobile application), strives to endorse responsible gaming, and improve the prevention and avoidance of excessive gaming. This Responsible Gaming Policy describes the Company’s commitments, and your responsibility to promote responsible gaming practices and minimize the negative effects of excessive gaming. Games may involve an element of financial risk and may be addictive. Please play responsibly and at your own risk.</p>
    <p>If you choose to play on our Website, there are some general guidelines that can help make your playing experience safer, and avoid risk that you may indulge in excessive gaming:</p>
    <ul>
        <li>Add money to the Gravity Technologies Wallet only with money that you can afford to spend.</li>
        <li>Never add money to the Gravity Technologies Wallet with money that you need for important or essential things such as food, rent, bills, or tuition.</li>
        <li>Pre-plan your purchases and the time you will spend playing on the Website and stick to that limit, regardless of your performance.</li>
        <li>Don't play when you are upset, tired, or depressed. It is difficult to make good decisions when you are feeling down.</li>
        <li>Do not put off personal and other important tasks in order to play. Complete all your important tasks so that you can play with a free and calm mind.</li>
        <li>Balance your gameplay duration with other activities. Find other forms of entertainment so playing does not become too big a part of your life.</li>
        <li>Do not borrow money in order to make purchases on the Website.</li>
        <li>Avoid using all of the monies in the Gravity Technologies Wallet in a single game or session.</li>
        <li>Take time outs and breaks regularly.</li>
        <li>Only participate in games that are commensurate with your competence level and financial capabilities. Do not get tempted to participate in high stakes tables despite winning consistently.</li>
    </ul>    
    <h4>Age Exclusion</h4>
    <p>Any underage player who has provided dishonest or inaccurate information regarding their true age may have all winnings forfeited and could face criminal prosecution.</p>
    <ul>
        <li>Every person signing up for a new account must check a box that indicates that they are at least 18 years of age. This notifies everybody that we don’t accept players under 18.</li>
        <li>When a player creates an account with us, we allow them an option to provide their name, address, and birth date for maintaining their user profile on the Website.</li>
        <li>We never target underage players with our marketing and advertising. It is neither good business nor consistent with our personal and corporate values to attract underage players.</li>    
    </ul>
    <h4>Company Responsibilities</h4>
    <p>We may notify users in case their accrued spending exceeds a certain limit. Users may be notified when their game sessions are long to take an optional break. And if users’ session exceeds a certain time, the Company may mandate the user to take a non-negotiable break. The user, in this case, may not use the Website.</p>
    <h4>How do you know if you may be indulging in excessive gaming?</h4>
    <p>Ask yourself the following questions:</p>
    <br />
    <ul>
        <li>Have you often played games longer than you had planned?</li>
        <li>Have you often played games until your last rupee was gone?</li>
        <li>Have you used your income or savings to play games while letting bills go unpaid?</li>
        <li>Have you made repeated, unsuccessful attempts to stop playing games?</li>
        <li>Have you considered breaking the law to finance your gaming activities?</li>
        <li>Have you borrowed money to finance your gaming activities?</li>
        <li>Have you felt depressed because of money you may have lost playing games?</li>
        <li>Have you been remorseful after you have spent time playing games?</li>
        <li>Have you played games to get money to meet your financial obligations?</li>
    </ul>
    <p><b>If you or someone you know answers yes to any of the above questions, please consider seeking professional help or advice.</b></p>    
    
    





    <br /><br /><br />
    </div>
    </div>

</>

}; 


export default ResponsibleGamingPolicy;

