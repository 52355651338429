
import React from "react"
import logo from "../logo.png";
import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from "fortawesome/react-fontawesome";
// import {
//     faYoutube,
//     faFacebook,
//     faTwitter,
//     faInstagram
//   } from "fortawesome/free-brands-svg-icons";

const Footer = () => <footer className="page-footer font-small blue pt-4" style={{'background-color': '#E9E9E9'}}>

    <div className="container text-center text-md-left">

        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
                
                <h3>Gravity Technologies</h3>
                {/* <div className="row">
                        <div className="col-3 mx-auto"> */}

                {/* <a>
                            <li class="fab fa-instagram fontawseome-style"></li>
                            </a>
                        </div>
                        <div className="col-3 mx-auto">
                            <a>
                            <li class="fab fa-instagram fontawseome-style"></li>
                            </a>
                        </div>
                        <div className="col-3 mx-auto">
                            <a>
                            <li class="fab fa-instagram fontawseome-style"></li>
                            </a> */}
                {/* </div> */}
                <h4>DISCLAIMER</h4>
                <span>All the games and formats offered on Gravity Technologies are those that involve preponderance of skill.Gravity Technologies is an absolutely safe and secure social gaming platform which ensures that all the games listed on the platform are fair. Laden with a real time and secure deposits and withdrawals ecosystem. We also have enhanced levels of fraud detection mechanisms to restrict fraudulent play and/or players thereby making Gravity Technologies a fair and safe platform.
                </span>
                {/* </div> */}
            </div>

            <hr className="clearfix w-100 d-md-none pb-0" />

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">Usefull Links</h5>
                <ul className="list-unstyled">
                <li class="nav-item">
                        <Link class="nav-link" to="/about">ABOUT</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/contact">CONTACT</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/t&c">TERM & CONDITION</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/privacyPolicy">PRIVACY POLICY</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/referEarn">REFER AND EARN</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/responsible-gaming-policy">RESPONSIBLE GAMING POLICY</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/anti-money-laundering-policy">ANTI-MONEY LAUNDERING POLICY</Link>
                    </li>

                </ul>
            </div>

            {/* <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">App Link</h5>
                <ul className="list-unstyled">
                <li class="nav-item">
                        <Link class="nav-link" to="/referEarn">DOWNLOAD APP</Link>
                    </li>
                    <li><a href="#!">Link 1</a></li>
                    <li><a href="#!">Link 2</a></li>
                    <li><a href="#!">Link 3</a></li>
                    <li><a href="#!">Link 4</a></li>
                </ul>
            </div> */}
        </div>
    </div>


    <div className="footer-copyright text-center py-3">© 2022 Copyright & All Right Reserved - <b>Gravity Technologies</b>
    </div>

</footer>

export default Footer



