/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  FormControl,
  Button,
  FilledInput,
  InputLabel,
  Container,
  Dialog,
  CircularProgress,
  AccordionDetails,
} from "@material-ui/core/";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paytm from "../paytm.png";
import Gpay from "../gpay.png";
import Bhim from "../bhim.jpeg";
import Phonepe from "../phonepe.png";
import QRCode from "react-qr-code";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const PreOrder = () => {
  const params = useParams();
  const formRef = useRef();
  const classes = useStyles();
  const URL = "https://app.gravitytechnologies.online";
  const history = useNavigate();
  const [user, setUser] = useState([{ balance: 0.0 }]);
  const [openDialog, setDialog] = React.useState({ open: false, body: "" });
  const [loader, setLoader] = React.useState(true);
  const [isBlocked, setBlock] = useState({ open: false, msg: "" });
  const [detail, setDetail] = useState({ name: "", email: "" });
  const [level, setLevel] = React.useState("UPI");

  // const [gateway, switchGateway] = useState(0);
  // const [paymentData, setPayment] = useState({
  //   txnid: "",
  //   key: "bKuywh",
  //   amount: 10.0,
  //   firstname: "",
  //   email: "",
  //   phone: "",
  //   productinfo: "",
  //   surl: "",
  //   furl: "",
  //   hash: "",
  // });

  const qrClose = () => {
    setqrDialog({ ...qrDialog, open: false });
  };

  useEffect(() => {
    const AuthStr = "Bearer ".concat(params.token);
    axios
      .get(`${URL}/getUser/${params.id}`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        setUser(response.data);
        setLoader(false);

        if (response.data[0].block) {
          setBlock({ ...isBlocked, open: true, msg: response[0].block });
        }
      })
      .catch((error) => {
        console.log(error);
        //history.push("/login");
      });
  }, []);
  const [amount, setAmount] = useState(500);
  const handleAmount = (money) => (e) => {
    setAmount(money);
  };
  const handleChange = (e) => {
    setAmount(parseInt(e.target.value));
  };
  const handleDetail = (e) => {
    setDetail({ ...detail, [e.target.name]: e.target.value });
  };
  const handleLevel = (level) => async (event) => {
    setLevel(level);
  };
  const handleSubmit = () => {};
  const dialogClose = () => {
    setDialog({ ...openDialog, open: false });
  };
  const [qrDialog, setqrDialog] = React.useState({ open: false });
  const [value, setValue] = useState("");

  // async function displayRazorpay() {
  //   // const res = await axios.post('http://localhost:5000/payment/payumoney');
  //   // console.log(res);
  //   // window.document.write(res.data);
  //   // setDialog({...openDialog, open: true, body: "We are updating our plateform. Functionality like recharge, withdrawal will be temporary paused. You can place bid and earn. We are sorry for any inconvenience caused. ThankYou !"})

  //   if (amount < 200 || !amount) {
  //     setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});

  //   }else{
  //     setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});

  //   }
  //   // } else
  //   // {
  //   //   setLoader(true);
  //   //   const res = await loadScript(
  //   //     "https://checkout.razorpay.com/v1/checkout.js"
  //   //   );

  //   //   if (!res) {
  //   //     alert("Razorpay SDK failed to load. Are you online?");
  //   //     return;
  //   //   }

  //   //   // creating a new order
  //   //   const result = await axios.post(`${api.url}/orders/${amount}`);

  //   //   if (!result) {
  //   //     alert("Server error. Are you online?");
  //   //     return;
  //   //   }

  //   //   // Getting the order details back
  //   //   const { id: order_id } = result.data;
  //   //   console.log(order_id);
  //   //   const options = {

  //   //     key: "rzp_live_b1N1FsiIFg2EFT", // Enter the Key ID generated from the Dashboard
  //   //     amount: amount.toString(),
  //   //     currency: "INR",
  //   //     name: "V1club",
  //   //     description: "Purchase Payment",
  //   //     order_id: order_id,
  //   //     prefill:
  //   //     {
  //   //       "email": detail.email,
  //   //       "contact": user[0].phone,
  //   //     },

  //   //     handler: async function (response) {
  //   //       // console.log(response.razorpay_payment_id);
  //   //       // console.log(response.razorpay_order_id);
  //   //       // console.log(response.razorpay_signature);
  //   //       const data = {
  //   //         orderCreationId: order_id,
  //   //         razorpayPaymentId: response.razorpay_payment_id,
  //   //         razorpayOrderId: response.razorpay_order_id,
  //   //         razorpaySignature: response.razorpay_signature,
  //   //         userId: user[0].id,
  //   //         firstRecharge: user[0].firstRecharge,
  //   //         userDate: user[0].date,
  //   //         amount: amount,
  //   //         upLine: user[0].upLine[0],
  //   //       };

  //   //       const result = await axios.post(`${api.url}/paymentSuccess`, data);
  //   //       setLoader(false);
  //   //       window.location.replace("/rechargeHistory");
  //   //     },
  //   //     // prefill: {
  //   //     //     name: "Soumya Dey",
  //   //     //     email: "SoumyaDey@example.com",
  //   //     //     contact: "9999999999",
  //   //     // },
  //   //     // notes: {
  //   //     //     address: "Soumya Dey Corporate Office",
  //   //     // },
  //   //     // theme: {
  //   //     //     color: "#61dafb",
  //   //     // },
  //   //   };
  //   //   setLoader(false);

  //   //   const paymentObject = new window.Razorpay(options);
  //   //   paymentObject.open();
  //   // }
  // }

  // useEffect(() => {
  //   if (statusParms === "success") {
  //     history.push("/rechargeHistory");
  //   }
  // }, []);

  const upiGateway = () => {
    var product = "Software development";
    if (amount === 10000) product = "React Node js Login/Signup Page";
    if (amount === 200) product = "Data Cable";
    if (amount === 500) product = "Wireless Mouse";
    if (amount === 1000) product = "Keyboard";
    if (amount === 5000) product = "React js Login/Signup Template";
    if (amount === 400) product = "Flutter Book";
    if (amount >= 15000) product = "Web Designing";
    if (amount >= 25000) product = "Web development";
    if (amount >= 30000) product = "App Development";

    //Create a Data object that is to be passed to LAUNCH method of Bolt
    if (!detail.name || detail.name.length < 2) {
      setDialog({
        ...openDialog,
        open: true,
        body: "Please enter valid name.",
      });
    } else {
      if (amount < 1 || amount > 200000) {
        setDialog({
          ...openDialog,
          open: true,
          body: "Minimum recharge is 200",
        });
      } else {
        setLoader(true);
        //setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});

        var pd = {
          amount: `${amount}`,
          p_info: product,
          customer_name: detail.name,
          customer_email: "email@mail.com",
          customer_mobile: `${user[0].phone}`,
          userId: `${user[0].id}`,
          redirect_url: `${URL}/upiVerifyPayment/${user[0].id}`,
        };

        fetch(`${URL}/upiCreateOrder`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pd),
        })
          .then(function (a) {
            return a.json();
          })
          .then(function (json) {
            if (json["status"] === true) {
              window.location.replace(json["data"]["payment_url"]);
              setLoader(false);
            }
          });
      }
    }
  };
  const planetC = () => {
    var product = "Software development";
    if (amount === 10000) product = "React Node js Login/Signup Page";
    if (amount === 200) product = "Data Cable";
    if (amount === 500) product = "Wireless Mouse";
    if (amount === 1000) product = "Keyboard";
    if (amount === 5000) product = "React js Login/Signup Template";
    if (amount === 400) product = "Flutter Book";
    if (amount >= 15000) product = "Web Designing";
    if (amount >= 25000) product = "Web development";
    if (amount >= 30000) product = "App Development";

    //Create a Data object that is to be passed to LAUNCH method of Bolt
    if (
      !detail.name ||
      !detail.email.includes("@") ||
      detail.email.length < 5 ||
      detail.name.length < 2 ||
      detail.name.length > 15
    ) {
      setDialog({
        ...openDialog,
        open: true,
        body: "Please enter valid name.",
      });
    } else {
      if (amount < 200 || amount > 10000) {
        // setsabpaisa({...sabpaisa, isOpen: true})
        setDialog({
          ...openDialog,
          open: true,
          body: "For UPI Instant amount range 200 ~ 10,000",
        });
      } else {
        // setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});
        setLoader(true);
        var pd = {
          amount: amount,
          p_info: product,
          customer_name: detail.name,
          customer_email: detail.email,
          customer_mobile: `${user[0].phone}`,
          userId: user[0].id,
          redirect_url: `${URL}/upiVerifyPayment/${user[0].id}`,
        };

        fetch(`${URL}/planetCreateLinkEt`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pd),
        })
          .then(function (a) {
            //window.location.replace(a['data']);
            return a.json();
          })
          .then(function (json) {
            if (json["link"].includes("upi")) {
              if (level === "QR") {
                setValue(json["link"]);
                setLoader(false);
                setqrDialog({ open: true });
              } else {
                // window.location.replace(json['data']);
                // if(json['status'] === true){
                // console.log(json['link'])
                window.location.replace(json["link"]);
                setLoader(false);
                // }
              }
            } else {
              setLoader(false);
            }
          });
      }
    }
  };
  const rojpg = () => {
    var product = "Software development";
    if (amount === 10000) product = "React Node js Login/Signup Page";
    if (amount === 200) product = "Data Cable";
    if (amount === 500) product = "Wireless Mouse";
    if (amount === 1000) product = "Keyboard";
    if (amount === 5000) product = "React js Login/Signup Template";
    if (amount === 400) product = "Flutter Book";
    if (amount >= 15000) product = "Web Designing";
    if (amount >= 25000) product = "Web development";
    if (amount >= 30000) product = "App Development";

    //Create a Data object that is to be passed to LAUNCH method of Bolt
    if (!detail.name || detail.name.length < 2) {
      setDialog({
        ...openDialog,
        open: true,
        body: "Please enter valid name.",
      });
    } else {
      if (amount < 200 || amount > 100000) {
        setDialog({
          ...openDialog,
          open: true,
          body: "Minimum recharge is 200",
        });
      } else {
        setLoader(true);
        //setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});

        var pd = {
          amount: amount,
          p_info: product,
          customer_name: detail.name,
          customer_email: "email@mail.com",
          customer_mobile: `${user[0].phone}`,
          userId: `${user[0].id}`,
          redirect_url: `${URL}/upiVerifyPayment/${user[0].id}`,
        };

        fetch(`${URL}/rojpgSubmit`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(pd),
        })
          .then(function (a) {
            return a.json();
          })
          .then(function (json) {
            if (level === "QR") {
              console.log(`upi${json["payment_url"]}`);

              setLevel(`upi${json["payment_url"]}`);
              setLoader(false);
              setqrDialog({ open: true });
            } else {
              // window.location.replace(json['data']);
              // if(json['status'] === true){
              console.log(`upi${json["payment_url"]}`);

              window.location.replace(`upi${json["payment_url"]}`);
              setLoader(false);
              // }
            }

            // // console.log(json['payment_url']);

            // // if(json['status'] === true){

            //   window.location.replace(`upi${json['payment_url']}`);
            //  setLoader(false)
            // // }
          });
      }
    }
  };

  const phonePe = () => {
    if (amount < 10 || amount > 200000) {
      setDialog({
        ...openDialog,
        open: true,
        body: "Minimum recharge is 200",
      });
    } else {
        // setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});

      setLoader(true);
      const AuthStr = "Bearer ".concat(user[0].token);
      fetch(`${URL}/initiatePhonePePG/${user[0].id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: AuthStr,
        },
        body: JSON.stringify({
          amount,
          userId: user[0].id,
          phone: user[0].phone,
        }),
      })
        .then(function (a) {
          return a.json();
        })
        .then(function (json) {
          console.log(json);

          window.location.replace(json["url"]);
        });
    }
  };

  // const payumoney = (e) => {
  //   e.preventDefault();
  //   var product = "Software development";
  //   if (amount === 10000) product = "React Node js Login/Signup Page";
  //   if (amount === 200) product = "Data Cable";
  //   if (amount === 500) product = "Wireless Mouse";
  //   if (amount === 1000) product = "Keyboard";
  //   if (amount === 5000) product = "React js Login/Signup Template";
  //   if (amount === 400) product = "Flutter Book";
  //   if (amount >= 15000) product = "Web Designing";
  //   if (amount >= 25000) product = "Web development";
  //   if (amount >= 30000) product = "App Development";

  //   //Create a Data object that is to be passed to LAUNCH method of Bolt
  //   if (
  //     !detail.name ||
  //     detail.name.length < 2 ||
  //     !detail.email ||
  //     !detail.email.includes("@") ||
  //     detail.email.length < 5
  //   ) {
  //     setDialog({
  //       ...openDialog,
  //       open: true,
  //       body: "Please enter valid name & email.",
  //     });
  //   } else {
  //     if (amount < 200 || amount > 200000) {
  //       setDialog({
  //         ...openDialog,
  //         open: true,
  //         body: "Minimum recharge is 200",
  //       });
  //     } else {
  //       //setDialog({ ...openDialog, open: true, body: 'Recharge is paused. We are on maintenance.'});
  //       var pd = {
  //         txnid: "",
  //         key: "00fCku",
  //         amount: amount,
  //         firstname: detail.name,
  //         email: detail.email,
  //         phone: user[0].phone,
  //         productinfo: product,
  //         surl: `${URL}/payment/payumoney/response/${user[0].id}`,
  //         furl: `${URL}/payment/fail`,
  //         hash: "",
  //       };

  //       //Data to be Sent to API to generate hash.
  //       let data = {
  //         email: pd.email,
  //         amount: pd.amount,
  //         productinfo: pd.productinfo,
  //         firstname: pd.firstname,
  //       };

  //       //API call to get the Hash value
  //       fetch(`${URL}/payment/payumoney`, {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       })
  //         .then(function (a) {
  //           return a.json();
  //         })
  //         .then(function (json) {

  //           pd.hash = json["hash"];
  //           pd.txnid = json["txnid"];
  //           setPayment({
  //             ...paymentData,
  //             key: pd.key,
  //             txnid: pd.txnid,
  //             amount: pd.amount,
  //             firstname: pd.firstname,
  //             email: pd.email,
  //             phone: pd.phone,
  //             productinfo: pd.productinfo,
  //             hash: pd.hash,
  //             surl: pd.surl,
  //             furl: pd.furl
  //           });

  //           if(paymentData.hash.length !== 0){
  //             setLoader(true);
  //             formRef.current && formRef.current.submit();
  //           }

  //         });
  //     }
  //   }
  // };

  // const cashfree = () => {
  //   var product = "Software development";
  //   if (amount === 10000) product = "React Node js Login/Signup Page";
  //   if (amount === 200) product = "Data Cable";
  //   if (amount === 500) product = "Wireless Mouse";
  //   if (amount === 1000) product = "Keyboard";
  //   if (amount === 5000) product = "React js Login/Signup Template";
  //   if (amount === 400) product = "Flutter Book";
  //   if (amount >= 15000) product = "Web Designing";
  //   if (amount >= 25000) product = "Web development";
  //   if (amount >= 30000) product = "App Development";

  //   //Create a Data object that is to be passed to LAUNCH method of Bolt
  //   if (
  //     !detail.name ||
  //     detail.name.length < 2 ||
  //     !detail.email ||
  //     !detail.email.includes("@") ||
  //     detail.email.length < 5
  //   ) {
  //     setDialog({
  //       ...openDialog,
  //       open: true,
  //       body: "Please enter valid name & email.",
  //     });
  //   } else {
  //     if (amount < 200 || amount > 200000) {
  //       setDialog({
  //         ...openDialog,
  //         open: true,
  //         body: "Minimum recharge is 200",
  //       });
  //     } else {
  //       setDialog({
  //         ...openDialog,
  //         open: true,
  //         body: "Recharge is paused. We are on maintenance.",
  //       });

  //       // // Data to be Sent to API to generate hash.
  //       // let data = {
  //       //     'email': detail.email,
  //       //     'amount': amount,
  //       //     'returnUrl':`https://server.vgaclub.in/payment/return/${user[0].id}`,
  //       //     'name': detail.name,
  //       //     'phone':user[0].phone,
  //       //     'orderNote':product
  //       // }
  //       // fetch(`${URL}/payment/cashfree`, {
  //       //     method: 'POST',
  //       //     headers: {
  //       //         'Accept': 'application/json',
  //       //         'Content-Type': 'application/json'
  //       //     },
  //       //     body: JSON.stringify(data)
  //       // })
  //       // .then(function (a) {
  //       //     return a.json();

  //       // })
  //       // .then(function (json) {
  //       //   var furl = 'https://www.cashfree.com/checkout/post/submit';
  //       //   if(furl){
  //       //     var form = document.createElement("form");
  //       //     form.setAttribute("method", "post");
  //       //     form.setAttribute("name", "frm1");
  //       //     form.setAttribute("action", furl);
  //       //     var dataPost = json.request;
  //       //     for(const key in dataPost){
  //       //       var input = document.createElement('input');
  //       //       input.setAttribute('type', 'hidden');
  //       //       input.setAttribute('value', dataPost[key]);
  //       //       input.setAttribute('name', key);
  //       //       form.appendChild(input);
  //       //     }
  //       //     document.body.appendChild(form);
  //       //     setTimeout(() => {
  //       //       form.submit();
  //       //     }, 2000);

  //       //   }

  //       // });
  //     }
  //   }
  // };

  return (
    <div>
      <Dialog
        open={isBlocked.open}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Container
          align="center"
          style={{
            backgroundColor: "black",
            opacity: "0.6",
            height: "80px",
            paddingTop: "10px",
          }}
        >
          <Typography style={{ paddingTop: "10px", color: "white" }}>
            {isBlocked.msg}
          </Typography>
        </Container>
      </Dialog>
      <Dialog
        open={qrDialog.open}
        onClose={qrClose}
        PaperProps={{
          style: {
            // backgroundColor: 'transparent',
            boxShadow: "none",
          },
        }}
      >
        <Container
          style={
            {
              // backgroundColor: 'black', opacity: '0.6', height: '50px'
            }
          }
        >
          <Typography style={{ padding: "10px", color: "black" }}>
            <Typography
              variant="h6"
              align="center"
              style={{ paddingTop: "10px", fontSize: "14px" }}
            >
              Pay Through
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <img src={Paytm} alt="paytm" style={{ height: "45px" }} />
              </Grid>
              <Grid item>
                <img src={Phonepe} alt="" style={{ height: "45px" }} />
              </Grid>
              <Grid item>
                <img src={Gpay} alt="" style={{ height: "45px" }} />
              </Grid>
              <Grid item>
                <img src={Bhim} alt="" style={{ height: "45px" }} />
              </Grid>
            </Grid>
            <Container
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 500,
                width: "100%",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={value}
                viewBox={`0 0 256 256`}
              />
            </Container>
          </Typography>
        </Container>
      </Dialog>
      <Dialog
        open={loader}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Container
          align="center"
          style={{
            backgroundColor: "black",
            opacity: "0.6",
            height: "100px",
            paddingTop: "10px",
          }}
        >
          <CircularProgress style={{ color: "white" }} />
          <Typography style={{ paddingTop: "10px", color: "white" }}>
            Please Wait!
          </Typography>
        </Container>
      </Dialog>
      <Dialog
        open={openDialog.open}
        onClose={dialogClose}
        PaperProps={{
          style: {
            // backgroundColor: 'transparent',
            boxShadow: "none",
          },
        }}
      >
        <Container
          style={
            {
              // backgroundColor: 'black', opacity: '0.6', height: '50px'
            }
          }
        >
          <Typography style={{ padding: "10px", color: "black" }}>
            {openDialog.body}
          </Typography>
        </Container>
      </Dialog>
      <Grid
        container
        direction="row"
        justify=""
        alignItems="center"
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={4}>
          <ArrowBackIosIcon
            onClick={() => history(-1)}
            style={{ fontSize: "20px", color: "black" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography align="center" style={{ color: "black" }}>
            Pre Order
          </Typography>
        </Grid>
      </Grid>
      <Typography
        align="center"
        style={{ padding: "20px", fontSize: "19px", color: "#3C6E71" }}
      >
        My balance ₹ {user && user[0].balance.toFixed(2)}
      </Typography>
      {/* <Grid
        container
        justify="space-evenly"
        direction="row"
        style={{ padding: "10px" }}
      >
        <Grid item xs={6} onClick={handleLevel("UPI")}>
          <Container
            style={{
              width: "100%",
              height: "30px",
              border: "1px solid",
              borderColor: "#3C6E71",
              backgroundColor: level === "UPI" ? "#3C6E71" : "white",
            }}
          >
            <Typography
              align="center"
              style={{
                fontSize: "15px",
                color: level === "UPI" ? "white" : "black",
                paddingTop: "3px",
              }}
            >
              UPI
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={6} onClick={handleLevel("QR")}>
          <Container
            style={{
              width: "100%",
              height: "30px",
              border: "1px solid",
              borderColor: "#3C6E71",
              backgroundColor: level === "QR" ? "#3C6E71" : "white",
            }}
          >
            <Typography
              align="center"
              style={{
                fontSize: "15px",
                color: level === "QR" ? "white" : "black",
                paddingTop: "3px",
              }}
            >
              QR
            </Typography>
          </Container>
        </Grid>
      </Grid> */}

      {/* <Container style={{ padding: "20px" }}>
        <FormControl fullWidth variant="filled" InputProps={{ classes }}>
          <InputLabel InputProps={{ classes }}>Enter your name</InputLabel>
          <FilledInput
            InputProps={{ classes }}
            fullWidth="true"
            name="name"
            id="filled"
            onChange={handleDetail}
            style={{ backgroundColor: "white", textUnderlineOffset: "none" }}
          />
        </FormControl>
      </Container> */}
      {/* <Container style={{ padding: "20px" }}>
        <FormControl fullWidth variant="filled" InputProps={{ classes }}>
          <InputLabel InputProps={{ classes }}>Enter your email</InputLabel>
          <FilledInput
            InputProps={{ classes }}
            fullWidth="true"
            name="email"
            type="email"
            id="filled"
            onChange={handleDetail}
            style={{ backgroundColor: "white", textUnderlineOffset: "none" }}
          />
        </FormControl>
      </Container> */}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              paddingRight: "15px",
              paddingTop: "15px",
            }}
          >
            ₹
          </Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth variant="filled">
            <InputLabel></InputLabel>
            <FilledInput
              value={amount}
              type="number"
              fullWidth="true"
              name="amount"
              id="filled"
              onChange={handleChange}
              style={{
                backgroundColor: "#fafafa",
                textUnderlineOffset: "none",
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {amount < 5000 ? (
        <Typography style={{ color: "red", fontWeight: "bold" }}>
          Recharge with amount ₹5000 and above you will get 10% extra !
        </Typography>
      ) : (
        <Typography style={{ color: "green", fontWeight: "bold" }}>
          You will get ₹{amount + (amount * 10) / 100}
        </Typography>
      )}
      <Container style={{ height: "15px" }}></Container>
      <Grid
        spacing={1}
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{ padding: "15px" }}
      >
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 200 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              fontSize: "15px",
              fontWeight: amount === 200 ? "bold" : "normal",
              border: "1px solid",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(200)}
          >
            ₹200
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 500 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              fontSize: "15px",
              fontWeight: amount === 500 ? "bold" : "normal",
              border: "1px solid",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(500)}
          >
            ₹500
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 1000 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              fontSize: "15px",
              fontWeight: amount === 1000 ? "bold" : "normal",
              border: "1px solid",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(1000)}
          >
            ₹1000
          </Button>
        </Grid>
      </Grid>
      <Grid
        spacing={1}
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{ padding: "15px", paddingTop: "0px" }}
      >
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 5000 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              border: "1px solid",
              fontSize: "15px",
              fontWeight: amount === 5000 ? "bold" : "normal",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(5000)}
          >
            ₹5000
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 10000 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              fontSize: "15px",
              fontWeight: amount === 10000 ? "bold" : "normal",
              border: "1px solid",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(10000)}
          >
            ₹10000
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: amount === 20000 ? "#D8D8D8" : "white",
              width: "100%",
              height: "50px",
              fontSize: "15px",
              fontWeight: amount === 20000 ? "bold" : "normal",
              border: "1px solid",
              borderColor: "#D8D8D8",
            }}
            onClick={handleAmount(20000)}
          >
            ₹20000
          </Button>
        </Grid>
      </Grid>
      <Container
        style={{
          backgroundColor: "#3C6E71",
          height: "50px",
          width: "100%",
          borderRadius: "20px",
        }}
        onClick={phonePe}
      >
        <Typography align="center">
          <Button
            type="submit"
            style={{
              paddingTop: "12px",
              color: "white",
              textTransform: "none",
            }}
          >
            Submit
          </Button>
        </Typography>
      </Container>

      {/* { gateway === 0 ?  <form
        ref={formRef}
        onSubmit={(e) => payumoney(e)}
        //action="https://servider.in"
        action="https://secure.payu.in/_payment"
        method="post"
      >
        <input type="hidden" name="key" value={paymentData.key} />
        <input type="hidden" name="txnid" value={paymentData.txnid} />
        <input type="hidden" name="productinfo" value={paymentData.productinfo} />
        <input type="hidden" name="amount" value={paymentData.amount} />
        <input type="hidden" name="email" value={paymentData.email} />
        <input type="hidden" name="firstname" value={paymentData.firstname} />
        <input type="hidden" name="surl" value={paymentData.surl} />
        <input type="hidden" name="furl" value={paymentData.furl} />
        <input type="hidden" name="phone" value={paymentData.phone} />
        <input type="hidden" name="hash" value={paymentData.hash} />
        
        <Container
          style={{
            backgroundColor: "#00b8a9",
            height: "50px",
            width: "100%",
            borderRadius: "20px",
          }}
          //onClick={displayRazorpay}
        >
          <Typography align="center">
            <Button
              type="submit"
              style={{
                paddingTop: "12px",
                color: "white",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Typography>
        </Container>
        </form> : <Container
          style={{
            backgroundColor: "#00b8a9",
            height: "50px",
            width: "100%",
            borderRadius: "20px",
          }}
          onClick={displayRazorpay}
        >
          <Typography align="center">
            <Button
              type="submit"
              style={{
                paddingTop: "12px",
                color: "white",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Typography>
        </Container>} */}
      <Link
        to={`/rechargeHistory/${params.id}/${params.token}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <Container
          style={{
            backgroundColor: "grey",
            marginTop: "20px",
            marginBottom: "100px",
            height: "50px",
            width: "100%",
            borderRadius: "20px",
          }}
        >
          <Typography align="center">
            <Button
              type="submit"
              style={{
                paddingTop: "12px",
                color: "white",
                textTransform: "none",
              }}
            >
              Historical Records
            </Button>
          </Typography>
        </Container>
      </Link>
    </div>
  );
};

export default PreOrder;
