import React from "react";


const AboutUs = () =>{
return<>
<div style={{'backgroundColor': ' #fff'}}>
<div className="container-xl" >
    <br /><h2>AboutUs</h2>
    All the games and formats offered on Gravity Technologies are those that involve preponderance of skill.Gravity Technologies is an absolutely safe and secure social gaming platform which ensures that all the games listed on the platform are fair. Laden with a real time and secure deposits and withdrawals ecosystem. We also have enhanced levels of fraud detection mechanisms to restrict fraudulent play and/or players thereby making Gravity Technologies a fair and safe platform.
    <br /><br /><br />
    </div>
    </div>

</>

}; 


export default AboutUs;

