import{ React, useState} from "react";
import logo from "../logo.png";
import {Link}  from "react-router-dom";


const TopAppBar = () => {
  const [show, setShow] = useState(false)
  return (
    <section class="navbar-bg">
    <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
    <h4 style={{color: 'white'}}>Gravity Technologies</h4>
    
    <button class="navbar-toggler" 
    type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
    onClick={()=>setShow(!show)} >
    <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class={`collapse navbar-collapse ${show ? "show" : " "}`}  >
      <ul class="navbar-nav ms-auto">
      <li class="nav-item">
          <Link class="nav-link" to="/">Home</Link>
        </li>
        {/* <li class="nav-item">
          <Link class="nav-link" to="/snakegame">SnakGame</Link>
        </li> */}
        
       
      <li class="nav-item">
          <Link class="nav-link" to="/contact">Contact</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link" to="/about">About</Link>

        </li>
        
        
      </ul>
     
    </div>
  </div>
</nav>
</section>    
  );
};
export default TopAppBar;

