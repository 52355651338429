import { BorderColor } from '@mui/icons-material';
import { width } from '@mui/system';
import React, { Component } from 'react';


class MySnackGame extends React.Component{
    food_x = 150
    food_y = 150
    x=100
    y=100
    Arr = []
    key_name = 'none'
    state = {a:0}
    score = 2
    newScore = 0

    
    fn1 = (e) =>{
        this.key_name = e.key
    }

    food_coolied()
    {
        if ( this.x >= this.food_x - 10 && this.x <= this.food_x + 10 && this.y >= this.food_y - 10 && this.y <= this.food_y + 10)
            return true
        else 
        return false
    }

    snake_collide = ()=>{
        this.Arr.map (e =>
            {
            if(e[0] == this.x && e[1] == this.y)
            {
                
                this.score = 0
                this.Arr = []
                // alert(`score ${this.newScore}`)
            }
         } )
    }

    componentWillMount()
    {
        setInterval(() => {
           //  NEW food and score incriment
            if( this.food_coolied() ){
                this.food_x = Math.round (Math.random () * 395)
                this.food_y = Math.round (Math.random () * 395)
                this.newScore = this.score++ 
                

            }
                // snake lengtha ccording to score 
            if (this.Arr.length > this.score)
            {
                this.Arr.splice(0,1)
            }
            this.Arr.push([this.x, this.y])
            switch(this.key_name){
                case 'ArrowUp':
                    this.y -= 10
                    break
                case 'ArrowDown':
                    this.y += 10
                    break
                case 'ArrowLeft':
                    this.x -= 10
                    break
                case 'ArrowRight':
                    this.x += 10
                    break
            }
                // border cross
            if( this.x > 390){ this.x = 0 }
            if( this.x < 0) { this.x = 390 }
            if( this.y > 390){ this.y = 0 }
            if( this.y < 0) { this.y = 390 }
            this.snake_collide()
            
        this.setState({a:0})
        }, 200)
    }

    render(){
        return<div className='container'>
        <div style={{ paddingTop:'9px', margin:'auto', width:'50%' }}>
            <div style={{display:'flez',flexDirection:'column', fontSize:'38px', width:'500px', height:'500px' }}>
            {/* <h4>Gravity Technologies, Play Snack Game. </h4> */}
            {/* socrebox */}
            <div style={{ margin: 'auto', width: '70px', height:'30px', border: '1px solid blue',borderRadius:'10px', backgroundColor:'#e5edf1' , fontSize:'25px', textAlign:'center' }}>{ this.newScore } </div>
            
            <input type='button' value='play'  style={ { marginTop:'20px', fontSize: '20px', width:'60px', height : '35px', backgroundColor:'#fff', BorderColor:'#fff' } } onKeyDown={this.fn1} autoFocus  />
            
            {/* ground box */}
            <br />
            <div style={{ display:'flex', margin:'auto', position:'relative', width:'400px',height:'400px', border:'1px solid blue', borderRadius: '10px', background:'#e5edf1' }} >
            {/*  snake head  */}
            <div style={{ position:'absolute', left:this.x, top:this.y, width:'8px', height:'8px', borderRadius:'10px', border:'1px solid blue', background:'blue' }}></div>
             {/* snake tell  */}
            {
                this.Arr.map ( (e)=>
                    <div style={{ position:'absolute', left:e[0], top:e[1], width:'8px', height:'8px', borderRadius:'10px', border:'1px solid skyblue', background:'skyblue' }}></div>
                    )
            }
             {/* food */}
            <div style={{ position:'absolute', left:this.food_x, top:this.food_y, width:'8px', height:'8px', borderRadius:'10px', border:'1px solid green', background:'green' }}></div>

            </div>
            </div>
        </div>
        </div>;
    }
}

export default MySnackGame; 