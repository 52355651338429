import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, List, ListItem } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const RechargeHistory = () => {
  const params = useParams();
  const [record, setRecord] = useState();
  const [isAuth, setAuth] = useState(false);
  const [memberRecharge, setMemberRecharge] = useState({ balance: 0 });
  const history = useNavigate();
  const URL = "https://app.gravitytechnologies.online";

  useEffect(() => {
    const AuthStr = "Bearer ".concat(params.token);
    axios
      .get(`${URL}/getUser/${params.id}`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => {
        
        setRecord(response.data);
        if (response.data[0].block) {
        }
      })
      .catch((error) => {
        
        console.log(error);
        // history.push("/login");
      });
  }, []);

  return (
    <div style={{ paddingBottom: "80px" }}>
      <Grid
        container
        direction="row"
        justify=""
        alignItems="center"
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={4}>
          <ArrowBackIosIcon
            onClick={() => history(-1)}
            style={{ fontSize: "20px" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography align="center" st>
            Historical Records
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" style={{ padding: "10px" }}>
        <Grid item xs={5}>
          <Typography>Date</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Amount</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>Status</Typography>
        </Grid>
      </Grid>
      {record &&
        record[0].rechargeHistory &&
        record[0].rechargeHistory.map((record) => (
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                direction="row"
              >
                <Grid item xs={5}>
                  <Typography style={{ fontSize: "10px" }}>
                    {new Date(record.date).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontWeight: "bold" }}>
                    ₹{record.amount}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ color: "green" }}>Success</Typography>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        ))}
    </div>
  );
};

export default RechargeHistory;
