import React from "react";


const AntiMonyLaunderingPolicy = () =>{
return<>
<div style={{'backgroundColor': ' #fff'}}>
<div className="container-xl" >
    <br /><h2>Anti-Money Laundering Policy</h2>
    <p>Gravity Technologies(Gravity Technologies) (Company, We, Us) is committed to carrying on business in accordance with the highest ethical and business standards. This includes complying with all applicable laws and regulations aimed at combating money laundering and related illegal activities.</p>
    <p>As an integral part of its business operations, the Company has instituted compliance measures commensurate with its Services, and to deter any potential illicit activity on its Platform. These measures may include, without limitation, onboarding and ongoing customer screening, and transaction-based controls.</p>
    <p>This Anti Money Laundering Policy (AML Policy) describes the Company’s measures, to detect and deter illicit activity on its Platform. It must be read in conjunction with our Terms of Use (https://www.gravitytechnologies.online/terms&conditions) (Terms) and Privacy Policy (https://www.gravitytechnologies.online/privacypolicy ) (Privacy Policy) and is binding on all users.</p>
    <p>Capitalized and undefined terms used in this AML Policy have the same meaning as in our Terms unless the context indicates otherwise.</p>
    <ol>
    <p><b> Identification Protocol User</b></p>

   
        <li>The Company takes reasonable measures to establish the identity of any person to whom it provides Services.</li>
        <li>In order to do so, the Company collects certain minimum player identification details from each User, who registers an account with Us, in accordance with our Terms and the Privacy Policy.</li>
        <li>Registered Users who wish to withdraw funds from their account, may be required to first have their accounts officially confirmed by the Company. For this, the Company may compulsorily require Users to provide valid photo identification, and address proof documents for proof of identity and address. Failure to provide the Company with a valid bank account or valid identification documents (to its satisfaction) may result in the forfeiture of amounts sought to be withdrawn by a User.</li>
        <li>Withdrawals may, therefore, be permitted subject strictly to the submission of a User’s PAN, Aadhaar verification, geolocation verification, and the withdrawal bank details, as per verified documents.</li>
        <li>The Company may also seek further documents from the Users to conduct Know Your Customer (KYC) verification, in accordance with applicable laws. Further, the Company reserves the right to request additional personal information from Users, in the interests of security, enhanced customer service, and compliance requirements. This includes, without limitation, Users’ proof of address, government-authorised identification cards, and other information/documents as may be required by the Company.</li>
    
    
    <p><b>User Obligations</b></p>
    
        <li>Users accept that they must comply with this AML Policy. Users specifically confirm that they neither do they intend to, and nor are they using the Platform and our Services to commit any listed or criminal offences.</li>
        <li>Users must ensure that they submit accurate and up-to-date personal information, that belongs to them.</li>
    
    
    
    
    <p><b>Transaction Monitoring</b></p>
   
        <li>We monitor activity on the Platform, through manual and automated means, on an ongoing basis. Such monitoring may be performed using rule-based systems developed in-house and by third parties, to review User history and activity patterns, to check for unusual or suspicious behaviour, and implement additional controls or limits on the Platform. Users may make payments for Services only through integrated payment gateways, through electronic means. We neither accept payments from Users, nor allow withdrawals by Users in the form of cash.</li>
        <li>If we detect unusual or suspicious User behaviour, we may take actions we deem appropriate, including without limitation:</li>
        <ul>
            <li>ask Users to submit more information and/or documents;</li>
            <li>mandate additional KYC verification;</li>
            <li>restrict, suspend, or terminate a User’s account;</li>
            <li>disallow withdrawal of funds by a User; and</li>
            <li>forfeit amounts sought to be withdrawn by a User.</li>
        </ul>
        <li>We monitor Users’ relationships in accordance with the best industry practices, and in alignment with international recommendations, guidelines, and commitments. The extent of monitoring may depend on various factors including upon each User’s risk profile, volume, value of transaction, time of transaction, and frequency of transactions with similar players, table etc.</li>
        <li>Any User account which may be involved in suspicious transactions potentially related to money laundering, or other offence, may be reported to the appropriate law enforcement agencies. For the purposes of this clause, suspicious transactions may mean and/or include:</li>
            <ul>
                <li>unusually complex transactions;</li>
                <li>transactions which do not appear to be undertaken in good faith;</li>
                <li>transactions which do not appear to have any economic basis;</li>
                <li>transactions which appear to involve proceeds of any offence listed in the Schedule to the Prevention of Money Laundering Act, 2002.</li>
            </ul>
        <li>We maintain records of transactions occurring on our Platform for such timelines that are required under the applicable laws.</li>
        <li>For compliance with this policy, we may reach out to you from time to time.</li>
        <p><b>Changes to AML Policy</b></p>
        <li>The Company may revise this AML Policy from time to time. Such changes may be made without prior notice. We will, in any event, periodically inform our users of this AML Policy, and any change in this Policy, as required by law.</li>
        <li>Updates to this AML Policy, if any, will be made available on our official website, https://www.gravitytechnologies.online </li>



    </ol>

    <br /><br /><br />
    </div>
    </div>

</>

}; 


export default AntiMonyLaunderingPolicy;

