import React from "react";


const ReferEarn = () =>{
return<>
<div style={{'backgroundColor': ' #fff'}}>
<div className="container-xl" >
<br /><br /><br />
    <h2>About Gravity Technologies Refer&Earn</h2>
    A unique referral program Gravity Technologies is the perfect platform for all the social media influencers to earn money and exciting rewards by inviting people on Gravity Technologies. Also the cash rewards earned can be withdrawn Gravity Technologies Application. So what are you waiting for, register yourself by following the quick steps mentioned below and start earning while referring.    </div>
    <br /><h3>How To Refer In Gravity Technologies</h3>
    <br /><br /> <br />
    </div>

</>

}; 


export default ReferEarn;

